import PropTypes from 'prop-types';
import { get } from 'lodash';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { useLocation } from 'react-router-dom';
import { Link } from '@remix-run/react';
import { useCity } from '~/services/hooks/useCity';
import { getParams } from '~/components/paramUtils';

const getClassName = props => {
  const { topBorder, positionBottom, transparent, poweredlight } = props;
  let className = `page-footer w-full text-gray-600 py-2 px-8 text-base rounded-b `;

  if (topBorder) className += 'border-t border-t-gray-200 ';
  if (positionBottom) className += 'fixed bottom-0 w-full bg-white z-0 ';
  if (transparent) className += 'bg-transparent ';
  if (poweredlight) className += 'text-primaryNav ';

  return className;
};

const NavFooter = props => {
  const cityContext = useCity();
  const location = useLocation();

  const queryParams = getParams(location?.search);
  if (queryParams.showFooter === 'false') {
    return null;
  }

  const hasCustomTerms = get(cityContext, 'cognitiveCity.terms.custom');
  const footerLinks = get(cityContext, 'cognitiveCity.footer.items');

  const termsContent = hasCustomTerms ? (
    <a
      className={
        'ml-[1em] text-[0.8em] text-gray-600 first-of-type:ml-0 hover:no-underline hover:shadow-none'
      }
      href="https://www.exaptive.com/powered"
      target="_blank"
      rel="noopener noreferrer"
    >
      Powered by Exaptive
    </a>
  ) : (
    <span className="nav-footer__copyright">Powered by Exaptive</span>
  );

  const footerContent = hasCustomTerms ? (
    footerLinks.map((item, index) => (
      <Link
        className="ml-[1em] text-[0.8em] text-gray-600 first-of-type:ml-0 hover:no-underline hover:shadow-none"
        to={sanitizeUrl(item.link)}
        target="_blank"
        rel="noopener noreferrer"
        key={index}
      >
        {item.label}
      </Link>
    ))
  ) : (
    <>
      <Link
        className="ml-[1em] text-[0.8em] text-gray-600 first-of-type:ml-0 hover:no-underline hover:shadow-none"
        to="mailto:support@exaptive.com"
        rel="noopener noreferrer"
      >
        Help
      </Link>
      <a
        className="ml-[1em] text-[0.8em] text-gray-600 first-of-type:ml-0 hover:no-underline hover:shadow-none"
        href="https://www.exaptive.com/terms"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms
      </a>
      <a
        className="ml-[1em] text-[0.8em] text-gray-600 first-of-type:ml-0 hover:no-underline hover:shadow-none"
        href="https://www.exaptive.com/privacy"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy
      </a>
    </>
  );

  return (
    <footer className={getClassName(props)}>
      <div>{termsContent}</div>
      <div>{footerContent}</div>
    </footer>
  );
};

NavFooter.propTypes = {
  topBorder: PropTypes.bool,
  fullWidth: PropTypes.bool,
  positionBottom: PropTypes.bool,
  transparent: PropTypes.bool,
  poweredlight: PropTypes.bool,
};

export default NavFooter;
